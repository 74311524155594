<template>
  <validation-observer
    v-slot="{ invalid }"
    ref="email_observer"
  >
    <core-section id="lets-talk">
      <core-heading>Let's talk.</core-heading>

      <v-col
        cols="12"
        md="5"
        class="mb-4 pr-md-5"
      >
        <core-subheading class="text-uppercase">
          What can I do for you?
        </core-subheading>

        <core-text class="mb-5">
          Please reach out if you have any questions or want to connect for possible work opportunities. I'm looking forward to hearing from you!
        </core-text>

        <core-subheading class="text-uppercase mb-3">
          Send me a Message
        </core-subheading>

        <v-form>
          <validation-provider
            v-slot="{ errors }"
            name="name"
            rules="required"
          >
            <v-text-field
              v-model="name"
              solo-inverted
              flat
              label="Name"
            />
            <span class="validation-error">{{ errors[0] }}</span>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="email"
            rules="required|email"
          >
            <v-text-field
              v-model="from"
              solo-inverted
              flat
              label="Email"
            />
            <span class="validation-error">{{ errors[0] }}</span>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="subject"
            rules="required"
          >
            <v-text-field
              v-model="subject"
              solo-inverted
              flat
              label="Subject"
            />
            <span class="validation-error">{{ errors[0] }}</span>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="message"
            rules="required"
          >
            <v-textarea
              v-model="message"
              solo-inverted
              flat
              label="Message"
            />
            <span class="validation-error">{{ errors[0] }}</span>
          </validation-provider>
          <v-btn
            class="ma-0"
            color="primary"
            :disabled="invalid || sendingEmail"
            :loading="sendingEmail"
            @click="sendEmail"
          >
            Contact Me
          </v-btn>
        </v-form>
      </v-col>

      <v-col
        cols="12"
        md="7"
        class="text-left"
      >
        <core-subheading class="text-uppercase">
          Social Contacts
        </core-subheading>

        <social-contacts />

        <v-list
          class="transparent"
          three-line
        >
          <v-list-item>
            <v-icon
              color="primary"
              size="48"
              class="mr-2"
            >
              mdi-map-marker
            </v-icon>
            <v-list-item-content>
              <v-list-item-title class="primary--text">
                Address
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ schema.basics.location.city }}, {{ schema.basics.location.state }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-icon
              size="48"
              color="primary"
              class="mr-2"
            >
              mdi-email
            </v-icon>
            <v-list-item-content>
              <v-list-item-title class="primary--text">
                Email
              </v-list-item-title>
              <v-list-item-subtitle v-text="schema.basics.email" />
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-icon
              size="48"
              color="primary"
              class="mr-2"
            >
              mdi-phone
            </v-icon>
            <v-list-item-content>
              <v-list-item-title class="primary--text">
                Phone
              </v-list-item-title>
              <v-list-item-subtitle v-text="schema.basics.phone" />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
    <!-- <v-img
      :src="require('@/assets/john-alt.png')"
      contain
      max-width="30vw"
      style="bottom: -20px; right: 0; position: absolute;"
      width="100%"
    /> -->
    </core-section>
  </validation-observer>
</template>

<script>
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'LetsTalk',

    components: {
      SocialContacts: () => import('@/components/SocialContacts'),
    },

    data () {
      return {
        name: '',
        from: '',
        subject: '',
        message: '',
        sendingEmail: false,
      }
    },

    computed: {
      ...mapState('app', ['schema']),
    },

    methods: {
      async sendEmail () {
        this.sendingEmail = true

        const AWS = require('aws-sdk')
        AWS.config.update({
          region: process.env.VUE_APP_AWS_REGION,
          accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
        })
        const lambda = new AWS.Lambda()

        const params = {
          FunctionName: 'emailer-elisawolensky',
          InvocationType: 'RequestResponse',
          Payload: JSON.stringify({
            from: this.from,
            name: this.name,
            subject: this.subject,
            message: this.message,
          }),
        }
        try {
          await lambda.invoke(params).promise()
          alert('Email sent sucessfully. I\'ll be in touch soon!')
          this.from = ''
          this.name = ''
          this.subject = ''
          this.message = ''
          this.$refs.email_observer.reset()
        } catch (e) {
          alert('There was a problem sending your email. Please reach out to me at elylouw17@yahoo.com from your email client.')
        } finally {
          this.sendingEmail = false
        }
      },
    },
  }
</script>
<style>
/**
 * Textarea was staying color: white after focusing, so users couldn't see the text
 */
.v-input--is-focused.v-input textarea {
  color: black !important;
}
.validation-error {
  width:100%;
  display:block;
  color: red;
  position: relative;
  top: -25px;
}
</style>
